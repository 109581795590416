<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharComprovante">
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row class="row-veiculo">
      <v-col class="col-comprovante">
        <p>
          Cavalo:
          <strong>{{
            atendimento.placa
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-horarios">
      <v-col class="col-comprovante">
        <p>Data/Hora:</p>
        <p>
          <strong>{{
            atendimento.atendido_em ? new Date(atendimento.atendido_em).toLocaleString() : '-'
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-senhas">
      <v-col class="col-comprovante">
        <p><strong> Senha </strong></p>
        <p style="font-size: 30px">
          {{
            atendimento.sequencia != null
              ?  atendimento.sequencia
              : '-'
          }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovante'],
  mixins: [toReal, date2br],
  data,
  computed,
  filters,
  async created() {
  },
  methods,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

.col-comprovante {
  padding: 1px;
}

.row-senhas strong {
  font-size: 20px;
  line-height: 20px;
}
strong {
  font-size: 15px;
  line-height: 15px;
}

p {
  font-size: 15px;
  line-height: 15px;
}
/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
