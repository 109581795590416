<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <list-filtro @search="handleGet" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <listar-senha-atendimento ref="listagem" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarSenhaAtendimento from '@/components/patio/SenhaAtendimento/listagem/Componente'
import ListFiltro from '@/components/patio/SenhaAtendimento/filtros/Componente'
export default {
  name: 'SenhaAtendimento',
  data() {
    return {
      dialog: false,
      page: {
        title: 'Gerenciamento de Senhas de atendimento',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Senhas de atendimento',
          disabled: false,
        },
      ],
      headers: [],
    }
  },
  methods: {
    async handleGet() {
      await this.$refs['listagem'].handleGetSenhas()
    },
  },
  components: {
    ListarSenhaAtendimento,
    ListFiltro,
  },
}
</script>

<style></style>
