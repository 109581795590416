var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-4"},[_c('v-dialog',{attrs:{"width":"700px"},model:{value:(_vm.showComprovante),callback:function ($$v) {_vm.showComprovante=$$v},expression:"showComprovante"}},[_c('v-card',[_c('comprovante')],1)],1),_c('v-dialog',{attrs:{"width":"700px"},model:{value:(_vm.showDocumentos),callback:function ($$v) {_vm.showDocumentos=$$v},expression:"showDocumentos"}},[_c('v-card',[_c('documentos')],1)],1),_c('div',{staticStyle:{"margin":"20px"}},[_c('v-btn',{staticClass:"w-100 my-1",attrs:{"color":"info"},on:{"click":_vm.getDownload}},[_c('v-icon',{staticClass:"mx-2",attrs:{"left":"","dark":""}},[_vm._v(" mdi-download ")]),_vm._v(" Baixar Planilha")],1)],1),_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headersComputed,"items":_vm.senhasAtendimento,"sort-by":"descricao","search":_vm.search,"server-items-length":_vm.senhasAtendimentoCount,"options":_vm.options},on:{"update:options":[function($event){_vm.options=$event},_vm.handleGetSenhas]},scopedSlots:_vm._u([{key:"item.sub_produto",fn:function(ref){
var item = ref.item;
return [(item.agendamento !== null)?_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.subProduto.descricao)+" ")])}),0):_vm._e()]}},{key:"item.numero_pedido",fn:function(ref){
var item = ref.item;
return [(item.agendamento !== null)?_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento.pedidos_agendados),function(ref,index){
var pedido = ref.pedido;
return _c('p',{key:index},[_vm._v(" "+_vm._s(pedido.numero_pedido)+" ")])}),0):_vm._e()]}},{key:"item.quantidade",fn:function(ref){
var item = ref.item;
return [(item.agendamento !== null)?_c('div',{staticClass:"div-pedido-info"},_vm._l((item.agendamento
              .pedidos_agendados),function(ref,index){
              var quantidade_agendada = ref.quantidade_agendada;
return _c('p',{key:index},[_vm._v(" "+_vm._s(_vm.converteKg(quantidade_agendada))+" ")])}),0):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Senhas de atendimento")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Indicadores")])]}}]),model:{value:(_vm.dialog_indicadores),callback:function ($$v) {_vm.dialog_indicadores=$$v},expression:"dialog_indicadores"}},[_c('indicadores',{attrs:{"headers":_vm.headers},on:{"close":_vm.close_indicadores}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Novo Atendimento")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Novo atendimento")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['AAA-XXX', 'AAA-XXXX']),expression:"['AAA-XXX', 'AAA-XXXX']"}],ref:"placa_cavalo",attrs:{"label":"Placa Cavalo"},model:{value:(_vm.editedItem.placa_cavalo),callback:function ($$v) {_vm.$set(_vm.editedItem, "placa_cavalo", $$v)},expression:"editedItem.placa_cavalo"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Gerar Senha")])],1)],1)],1)],1),_c('v-card-title',[_vm._v(" Filtro "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"pointer",attrs:{"title":"Visualizar Comprovante da Senha"},on:{"click":function($event){return _vm.handleVisualizarComprovante(item)}}},[_vm._v(" Comprovante da Senha ")]),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.handleVisualizarDocumentos(item.agendamento)}}},[_vm._v(" Documentos ")])],1)],1)]}},{key:"item.atendido_em",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.atendido_em ? new Date(item.atendido_em).toLocaleString() : '-')+" ")]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleGetSenhas}},[_vm._v("Reset")])]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDeleteItem),callback:function ($$v) {_vm.dialogDeleteItem=$$v},expression:"dialogDeleteItem"}},[_c('v-card',[_c('v-card-title',[_c('v-card-text',[_c('h3',{staticClass:"transition-swing text-h3 mb--1 error--text"},[_vm._v(" Cuidado! ")]),_c('p',{staticClass:"mt-8"},[_vm._v("Tem certeza que deseja deletar este registro?")])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogDeleteItem = false
              _vm.itemDelete = {}}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }