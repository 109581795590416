<template>
  <base-modal formTitle="Filtros" class="modal--indicadores">
    <template v-slot:header>
      <div class="col-12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn color="primary" plain small @click="clear()"> Limpar </v-btn>
        <v-btn color="primary" plain small @click="fill()">
          Selecionar Todos
        </v-btn>
      </div>
    </template>
    <template v-slot:body class="123">
      <div class="col-12">
        <v-checkbox
          v-for="(header, index) in headersComputed"
          :key="index"
          :label="header.text"
          v-model="header.habilited"
          hide-details
        />
      </div>
    </template>
    <template v-slot:buttons>
      <v-btn
        color="primary"
        dark
        type="button"
        class="button-submit-green"
        @click="clearLocalStorage()"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn type="button" class="button-submit-green" @click="close"
        >Salvar</v-btn
      >
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '@/components/BaseModal/BaseModal.vue'

import { SENHA_ATENDIMENTO } from '@/utils/storages'
import { latim_to_str } from '@/utils/string'

const LOCAL_STORAGE_ID = SENHA_ATENDIMENTO
export default {
  props: {
    headers: {
      type: Array,
    },
    localStorageKey: {
      type: String,
      default: LOCAL_STORAGE_ID.headers,
    },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    headersComputed() {
      let headers = this.headers.filter((item) => !item.block)
      if (this.search) {
        let search = latim_to_str(this.search)
        headers = headers.filter((item) => {
          return latim_to_str(item.text).includes(search)
        })
      }
      return headers
    },
  },
  methods: {
    clearLocalStorage() {
      localStorage.removeItem(this.localStorageKey)
    },
    clear() {
      this.headersComputed.forEach((element) => {
        element.habilited = false
      })
    },
    fill() {
      this.headersComputed.forEach((element) => {
        element.habilited = true
      })
    },
    close() {
      localStorage.setItem(
        LOCAL_STORAGE_ID.headers,
        JSON.stringify(this.headers)
      )
      this.$emit('close')
    },
  },
  components: { BaseModal },
  name: 'Indicadores',
}
</script>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
.modal--indicadores .v-card__text {
  padding-top: 0 !important;

  .container {
    padding-top: 0 !important;

    > div {
      padding-top: 6px !important;
    }
  }
}
</style>
