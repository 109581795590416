export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor: ['produto', 'sub_produto'],
      terminal: ['armazem'],
    },
    gates: [],
    params: {
      data_atendimento_inicio: {
        value: '',
        visible: false,
      },
      data_atendimento_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_agendamento_inicio: {
        value: '',
        visible: false,
      },
      data_agendamento_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_triagem_inicio: {
        value: '',
        visible: false,
      },
      data_triagem_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_cota_inicio: {
        value: '',
        visible: false,
      },
      data_cota_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_pedido_inicio: {
        value: '',
        visible: false,
      },
      data_pedido_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_saida_patio_inicio: {
        value: '',
        visible: false,
      },
      data_saida_patio_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_conclusao_inicio: {
        value: '',
        visible: false,
      },
      data_conclusao_fim: {
        value: '',
        visible: false,
        until: true,
      },
      gate: {
        value: '',
        visible: false,
        // get: () => { },
      },
      proprietario: {
        value: '',
        visible: false,
        // get: () => { },
      },
      fornecedor: {
        value: '',
        visible: false,
        //get: this.getFornecedores,
      },
      produto: {
        value: '',
        visible: false,
      },
      sub_produto: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        //get: this.getClientes,
      },
      transportadora: {
        value: '',
        visible: false,
        //get: this.getTransportadoras,
      },
      terminal: {
        value: '',
        visible: false,
        //get: this.getContratos,
      },
      armazem: {
        value: '',
        visible: false,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
      motorista: {
        value: '',
        visible: false,
        get: this.getMotoristas,
      },
      placa_cavalo: {
        value: '',
        visible: false,
      },
      placa_carreta_1: {
        value: '',
        visible: false,
      },
      placa_carreta_2: {
        value: '',
        visible: false,
      },
      eixo: {
        value: '',
        visible: false,
      },
    },
    allOptions: [
      'data_atendimento_inicio',
      'data_atendimento_fim',
      'data_agendamento_inicio',
      'data_agendamento_fim',
      'data_triagem_inicio',
      'data_triagem_fim',
      'data_cota_inicio',
      'data_cota_fim',
      'data_pedido_inicio',
      'data_pedido_fim',
      'data_saida_patio_inicio',
      'data_saida_patio_fim',
      'data_conclusao_inicio',
      'data_conclusao_fim',
      'fornecedor',
      'produto',
      'sub_produto',
      'terminal',
      'armazem',
      'cliente',
      'transportadora',
      'regiao',
      'representante',
      'motorista',
      'placa_cavalo',
      'placa_carreta_1',
      'placa_carreta_2',
      'eixo',
    ],
  }
}
