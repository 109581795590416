export const data = () => ({
  search: '',
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data Do Atendimento',
      children: [
        {
          id: 'data_atendimento_inicio',
          name: 'De',
        },
        {
          id: 'data_atendimento_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Data Do Agendamento',
      children: [
        {
          id: 'data_agendamento_inicio',
          name: 'De',
        },
        {
          id: 'data_agendamento_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 30,
      name: 'Data Da Triagem',
      children: [
        {
          id: 'data_triagem_inicio',
          name: 'De',
        },
        {
          id: 'data_triagem_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 40,
      name: 'Data Da Cota',
      children: [
        {
          id: 'data_cota_inicio',
          name: 'De',
        },
        {
          id: 'data_cota_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 50,
      name: 'Data Do Pedido',
      children: [
        {
          id: 'data_pedido_inicio',
          name: 'De',
        },
        {
          id: 'data_pedido_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 60,
      name: 'Data Da Saída  Do Patio',
      children: [
        {
          id: 'data_saida_patio_inicio',
          name: 'De',
        },
        {
          id: 'data_saida_patio_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 70,
      name: 'Data Da Conclusão',
      children: [
        {
          id: 'data_conclusao_inicio',
          name: 'De',
        },
        {
          id: 'data_conclusao_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 80,
      name: 'Fornecedor',
      children: [
        {
          id: 'fornecedor',
          name: 'Fornecedor',
        },
        {
          id: 'produto',
          name: 'Produto',
        },
        {
          id: 'sub_produto',
          name: 'Sub Produto',
        },
      ],
    },
    {
      id: 90,
      name: 'Contratos',
      children: [
        {
          id: 'terminal',
          name: 'Terminal',
        },
        {
          id: 'armazem',
          name: 'Armazem',
        },
        {
          id: 'cliente',
          name: 'Cliente',
        },
        {
          id: 'transportadora',
          name: 'Transportadora',
        },
      ],
    },
    {
      id: 100,
      name: 'Região',
      children: [
        {
          id: 'regiao',
          name: 'Região',
        },
        {
          id: 'representante',
          name: 'Representante',
        },
      ],
    },
    {
      id: 110,
      name: 'Veículo',
      children: [
        {
          id: 'motorista',
          name: 'Motorista',
        },
        {
          id: 'placa_cavalo',
          name: 'Placa',
        },
        {
          id: 'placa_carreta_1',
          name: 'Carreta 1',
        },
        {
          id: 'placa_carreta_2',
          name: 'Carreta 2',
        },
        {
          id: 'eixo',
          name: 'Eixo',
        },
      ],
    },
  ],
})
